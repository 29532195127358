import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { ChartMilestone, chartMilestoneConfig, chartPeriodConfig } from '@hooks/systems/constants';
import { DateTime } from 'luxon';
import { UseQueryOptions } from 'react-query/types/react/types';
import fleetChartsAPI, { ChartDTO, Meter, SystemEnergy, TotalEnergy } from '@services/api/fleetChartsAPI';

// TODO: REMOVE IT AFTER DRAFT MR
const fakeData: {
  [key: string]: [{ value: number | null }, { time: string; value: number }[]];
} = {
  '1': [
    { value: null },
    [
      { time: '2024-12-05 12:40:08+00', value: 0 },
      { time: '2024-12-05 12:55:08+00', value: 1232 },
      { time: '2024-12-05 13:10:08+00', value: 1963 },
      { time: '2024-12-05 13:25:08+00', value: 1527 },
      { time: '2024-12-05 13:40:08+00', value: 306 },
      { time: '2024-12-05 13:55:08+00', value: 483 },
      { time: '2024-12-05 14:10:08+00', value: 1615 }
    ]
  ],
  '16': [
    { value: null },
    [
      { time: '2024-12-05 12:40:08+00', value: 50 },
      { time: '2024-12-05 12:55:08+00', value: 100 },
      { time: '2024-12-05 13:10:08+00', value: 150 },
      { time: '2024-12-05 13:25:08+00', value: 200 },
      { time: '2024-12-05 13:40:08+00', value: 250 },
      { time: '2024-12-05 13:55:08+00', value: 300 },
      { time: '2024-12-05 14:10:08+00', value: 350 }
    ]
  ],
  '2': [
    { value: null },
    [
      { time: '2024-12-05 12:40:08+00', value: 0 },
      { time: '2024-12-05 12:55:08+00', value: 1827 },
      { time: '2024-12-05 13:10:08+00', value: 1171 },
      { time: '2024-12-05 13:25:08+00', value: 1426 },
      { time: '2024-12-05 13:40:08+00', value: 962 },
      { time: '2024-12-05 13:55:08+00', value: 417 },
      { time: '2024-12-05 14:10:08+00', value: 401 }
    ]
  ],
  '3': [
    { value: null },
    [
      { time: '2024-12-05 12:40:08+00', value: 10 },
      { time: '2024-12-05 12:55:08+00', value: 20 },
      { time: '2024-12-05 13:10:08+00', value: 30 },
      { time: '2024-12-05 13:25:08+00', value: 40 },
      { time: '2024-12-05 13:40:08+00', value: 50 },
      { time: '2024-12-05 13:55:08+00', value: 60 },
      { time: '2024-12-05 14:10:08+00', value: 70 }
    ]
  ],
  '18': [
    { value: null },
    [
      { time: '2024-12-05 12:40:08+00', value: 500 },
      { time: '2024-12-05 12:55:08+00', value: 700 },
      { time: '2024-12-05 13:10:08+00', value: 1200 },
      { time: '2024-12-05 13:25:08+00', value: 1500 },
      { time: '2024-12-05 13:40:08+00', value: 1800 },
      { time: '2024-12-05 13:55:08+00', value: 2000 },
      { time: '2024-12-05 14:10:08+00', value: 2200 }
    ]
  ],
  '19': [
    { value: null },
    [
      { time: '2024-12-05 12:40:08+00', value: 100 },
      { time: '2024-12-05 12:55:08+00', value: 200 },
      { time: '2024-12-05 13:10:08+00', value: 300 },
      { time: '2024-12-05 13:25:08+00', value: 400 },
      { time: '2024-12-05 13:40:08+00', value: 500 },
      { time: '2024-12-05 13:55:08+00', value: 600 },
      { time: '2024-12-05 14:10:08+00', value: 700 }
    ]
  ],
  '5': [
    { value: null },
    [
      { time: '2024-12-05 12:40:08+00', value: 250 },
      { time: '2024-12-05 12:55:08+00', value: 500 },
      { time: '2024-12-05 13:10:08+00', value: 750 },
      { time: '2024-12-05 13:25:08+00', value: 1000 },
      { time: '2024-12-05 13:40:08+00', value: 1250 },
      { time: '2024-12-05 13:55:08+00', value: 1500 },
      { time: '2024-12-05 14:10:08+00', value: 1750 }
    ]
  ],
  '20': [
    { value: null },
    [
      { time: '2024-12-05 12:40:08+00', value: 300 },
      { time: '2024-12-05 12:55:08+00', value: 600 },
      { time: '2024-12-05 13:10:08+00', value: 900 },
      { time: '2024-12-05 13:25:08+00', value: 1200 },
      { time: '2024-12-05 13:40:08+00', value: 1500 },
      { time: '2024-12-05 13:55:08+00', value: 1800 },
      { time: '2024-12-05 14:10:08+00', value: 2100 }
    ]
  ]
};

export type ProductionChartById = {
  [id: number | string]: {
    total: number;
    points: { time: DateTime; value: number }[];
  };
};

export const useSystemsProductionChartById = (systemsIds: number[], opts?: UseQueryOptions<ProductionChartById>) => {
  const milestoneConfig = chartMilestoneConfig[ChartMilestone.week]; // TODO: Use chart milestone as parameter
  const { period } = milestoneConfig;
  const statsInterval = milestoneConfig.interval();
  const startAt = statsInterval?.start;
  const endBefore = statsInterval?.end;

  return useQuery<ProductionChartById>(
    [ReactQueryKey.System, 'useSystemsProductionChartById', systemsIds, { period, startAt, endBefore }],
    async () => {
      try {
        const periodConfig = chartPeriodConfig[period];

        const chartsDto: ChartDTO[] = [
          {
            metric: 'energy',
            kind: 'total',
            meter: Meter.Production,
            period: periodConfig.totalGranularity.toISO(),
            startAt: startAt?.toISO(),
            endBefore: endBefore?.toISO()
          },
          {
            metric: 'energy',
            kind: 'totalTime',
            meter: Meter.Production,
            period: periodConfig.pointGranularity.toISO(),
            startAt: startAt?.toISO(),
            endBefore: endBefore?.toISO()
          }
        ];

        const { data } = await fleetChartsAPI.getSystemsChartsById(systemsIds, chartsDto, undefined);

        // TODO: ADD CACHE
        const mappedData = Object.fromEntries(
          Object.entries(data).map(([id, [total, points]]) => {
            const fakeDataEntry = fakeData[id] ?? [{ value: null }, []];
            const fakePoints = fakeDataEntry[1];
            const pointsToMap = (
              (points as SystemEnergy[]).length && (!fakePoints || fakePoints.length === 0)
                ? (points as SystemEnergy[])
                : fakePoints
            ) as SystemEnergy[];

            return [
              parseInt(id, 10),
              {
                total: (total as TotalEnergy).value || 0,
                points: pointsToMap.map((point) => ({
                  time: DateTime.fromSQL(point.time).toLocal(),
                  production: point.value,
                  value: point.value
                }))
              }
            ];
          })
        );

        return mappedData;
      } catch (e) {
        throw apiErrorHandler('Error fetching systems production', e);
      }
    },
    {
      initialData: {},
      ...opts
    }
  );
};

import { Checkbox } from '@material-ui/core';
import styled from 'styled-components';
import { scroll } from '@styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Scrollable = styled.div`
  overflow: auto;

  height: calc(
    100vh - 48px // page header
    - 49px // table filters
    - 16px // gap
    - 178px // widgets
    - 16px // gap
  );
  ${scroll};
`;

export const GroupContainer = styled.div`
  margin-bottom: 16px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
`;

export const HeaderContainer = styled.table`
  border-spacing: 0;
  th {
    height: 40px;
    background-color: #f8fafb;
    width: 180px;
    min-width: 180px;
    max-width: 180px;
  }
  th:first-child {
    background-color: #fff;
    position: sticky;
    left: 0px;
    z-index: 1;
    width: 396px;
    min-width: 396px;
    max-width: 396px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-right: 1px solid #dfdfe8;
    border-top: 1px solid #dfdfe8;
    border-left: 1px solid #dfdfe8;
  }
`;

export const Header = styled.div`
  padding: 10px 8px;
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  user-select: none;
`;

export const BulkCheckbox = styled(Checkbox)`
  && {
    margin-left: -2px;

    .MuiSvgIcon-fontSizeSmall {
      font-size: 26px;
    }
  }
`;

export const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  gap: 16px;
  width: 100%;
`;

export const CoveringMapOuter = styled.div`
  position: sticky;
  width: calc(
    100% - 206px - 136px - 96px - 160px - 105px
  ); // first 5 columns total size of the first 5 columns based on columnStyleWithMap
  height: 0;
  left: calc(
    206px + 136px + 96px + 160px + 105px
  ); // first 5 columns total size of the first 5 columns based on columnStyleWithMap
  top: calc(32px + 40px);
  z-index: 3;
`;

export const CoveringMapInner = styled.div`
  width: 100%;
  height: calc(
    100vh - 48px // page header
    - 49px // table filters
    - 16px // gap
    - 178px // widgets
    - 16px // gap
    - 32px // table controls
    - 40px // table header
  );
  border: solid 1px #d2d9e1;
`;

import { colors } from '@styles';
import styled from 'styled-components';

export const Header = styled.div`
  padding: 10px 8px;
  border: 1px solid #dfdfe8;
  border-bottom: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  align-items: center;
  gap: 20px;
  width: 396px;
  background-color: #fff;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`;

export const Container = styled.table`
  background-color: #fff;
  border-spacing: 0;
  border-top: 1px solid #dfdfe8;
  td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 180px;
    min-width: 180px;
    max-width: 180px;
    height: 60px;
    font-weight: 400;
    font-size: 14px;
    border-left: 1px solid #dfdfe8;
    border-bottom: 1px solid #dfdfe8;
    padding: 8px 16px;
    position: relative;

    &:nth-child(2) {
      border-left: 0;
    }
  }

  tfoot {
    position: relative;
  }

  tfoot td {
    height: 38px;
    position: sticky;
    left: 0px;
    z-index: 1;

    :first-child {
      border-bottom-left-radius: 4px;
      z-index: 2;
    }

    :last-child {
      border-bottom-right-radius: 4px;
    }
  }
`;

export const Row = styled.tr<{ isSelected: boolean; isDimmed: boolean }>`
  td > * {
    opacity: ${(props) => (props.isDimmed && 0.5) || 1};
  }

  td {
    background-color: ${(props) => (props.isSelected && '#E3F6F4') || (props.isDimmed && '#f8fafb') || '#fff'};
  }

  td,
  div {
    overflow: visible;
  }
`;

export const LoadMore = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${colors.green};
  cursor: pointer;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 5px;
`;
